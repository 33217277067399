import classNames from 'classnames'
import React from 'react'
import { DeepMap, FieldError, UseFormRegisterReturn } from 'react-hook-form'

export interface FormFieldBaseProps {
  label?: string
  register: UseFormRegisterReturn
  required?: boolean
  errors?: DeepMap<any, FieldError>
  autoFocus?: boolean
  helpText?: string
  containerClassName?: string
  size?: 'xl' | '2xl' | '3xl'
  currency?: boolean
  disabled?: boolean
  maxLength?: number
}

type FormFieldProps = FormFieldBaseProps &
  (
    | { type: 'text' | 'email' | 'date' | 'number' | undefined; defaultValue?: string | number }
    | { type: 'textarea'; rows?: number; defaultValue?: string }
    | { type: 'select'; options: { text: string; value: string }[]; includeEmpty?: boolean | string; defaultValue?: string }
  )

export const FormFieldErrorMessage = ({ fieldError, errorMessageId, helpText }: { fieldError?: FieldError | undefined; errorMessageId: string; helpText?: string }) => {
  if (!fieldError && !helpText) return null
  return (
    <p className={classNames('mt-2 text-xs font-light', { 'text-red-600': !!fieldError, 'text-gray-700': !fieldError })} id={errorMessageId}>
      {(() => {
        if (!fieldError && helpText) return helpText
        switch (fieldError?.type) {
          case 'required':
            return `Required`
          case 'pattern':
            'Invalid'
          default:
            return fieldError?.message
        }
      })()}
    </p>
  )
}

export const FormFieldLabel = ({
  generatedId,
  fieldError,
  required,
  label,
  type,
}: {
  generatedId: string
  fieldError?: FieldError
  required?: boolean
  label: string
  type?: string
}) => {
  return (
    <label htmlFor={generatedId} className={classNames('absolute -top-1 text-md pl-3 pt-3  duration-300 origin-0 font-light', { 'text-red-500': !!fieldError })}>
      {required && <span className='text-red-600'>*&nbsp;</span>}
      {label}
    </label>
  )
}

const FormFieldInput = (props: FormFieldProps) => {
  const { label, register, required, errors, type, autoFocus, defaultValue, containerClassName, size, disabled } = props
  const generatedId = `${register.name}`
  const errorMessageId = `error-${register.name}-${Math.ceil(Math.random() * 100)}`
  const fieldError = errors ? (errors[register.name] as FieldError) : undefined
  const fieldProps: React.InputHTMLAttributes<HTMLInputElement> = {
    id: generatedId,
    className: classNames(
      'block w-full focus:outline-none py-3 leading-none',
      (() => {
        if (props.type === 'number' && props.currency && !props.size) return 'pr-6'
        if (props.type === 'number' && props.currency && props.size) return 'pr-16'
        return 'p-3'
      })(),
      {
        // Have to spell these out because of the way the tailwind purges unused classes.
        'text-3xl': size === '3xl',
        'text-2xl': size === '2xl',
        'text-xl': size === 'xl',
        'text-md leading-none': !size,
        'form-input': true,
        'appearance-none': props.type !== 'select',
        'text-right': props.type === 'number' && props.currency,
        'bg-gray-300': props.disabled,
        'cursor-not-allowed': props.disabled,
      },
    ),
    'aria-invalid': !!errors,
    'aria-describedby': errorMessageId,
    placeholder: ' ',
    autoFocus,
    defaultValue,
    disabled,
    maxLength: props.maxLength,
    ...register,
  }

  if (type === 'date') fieldProps.max = '2099-12-31'

  const field = (() => {
    if (props.type === 'textarea') return <textarea {...(fieldProps as React.TextareaHTMLAttributes<HTMLTextAreaElement>)} rows={props.rows}></textarea>
    if (props.type === 'select')
      return (
        <select {...(fieldProps as React.SelectHTMLAttributes<HTMLSelectElement>)}>
          {props.includeEmpty && (
            <option key='empty' value=''>
              {typeof props.includeEmpty === 'string' && props.includeEmpty}
            </option>
          )}
          {props.options.map((opt) => (
            <option key={opt.value} value={opt.value}>
              {opt.text}
            </option>
          ))}
        </select>
      )
    return <input {...fieldProps} type={type}></input>
  })()
  return (
    <div className={classNames('my-6', containerClassName, { 'field-with-error': !!fieldError })}>
      <div
        className={classNames('outline relative border border-gray-300', {
          'focus-within:border-wisteria': !fieldError,
          'focus-within:border-red-500 border-red-500': !!fieldError,
          'pr-3': props.type === 'select',
        })}
      >
        {type === 'number' && props.currency && (
          <span className={classNames(`form-input-prepend absolute top-0 left-0 pl-3 pt-3 `, size ? `text-${size}` : `leading-none`)}>$</span>
        )}
        {field}
        {type === 'number' && props.currency && (
          <span className={classNames('form-input-append absolute top-0 pt-3 pr-3 right-0  font-thin', size ? `text-${size}` : `leading-none`)}>.00</span>
        )}
        {label && <FormFieldLabel generatedId={generatedId} fieldError={fieldError} required={required} label={label} type={type} />}
      </div>
      <FormFieldErrorMessage fieldError={fieldError} errorMessageId={errorMessageId} helpText={props.helpText} />
    </div>
  )
}

export default FormFieldInput
