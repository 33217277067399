import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { ButtonHTMLAttributes } from 'react'

const Button = ({
  type,
  color,
  size,
  loading,
  children,
  icon,
  fullWidth,
  outline,
  ...props
}: {
  color?: 'primary' | 'secondary' | 'danger' | 'outline'
  loading?: boolean
  size?: 'small' | 'x-small'
  children?: JSX.Element | string
  icon?: JSX.Element
  fullWidth?: boolean
  outline?: boolean
} & ButtonHTMLAttributes<HTMLButtonElement>) => {
  const buttonType = color
  return (
    <button
      {...props}
      disabled={props.disabled || loading}
      className={classNames(
        'btn',
        {
          // btn__primary: buttonType === 'primary',
          // btn__secondary: buttonType === 'secondary',
          // btn__danger: buttonType === 'danger',
          // btn__small: size == 'small',
          // 'w-full': fullWidth,
          'btn-primary': buttonType === 'primary',
          'btn-secondary': buttonType === 'secondary',
          'btn-error': buttonType === 'danger',
          'btn-outline': outline,
          'btn-sm': size == 'small',
          'btn-xs': size == 'x-small',
          'w-full': fullWidth,
        },
        props.className,
      )}
    >
      <div className='flex-1 align-top'>
        {(icon || loading) && <div className='h-4 inline-block mr-2'>{loading ? <FontAwesomeIcon icon={faSpinner} className='stroke-current' spin /> : <>{icon}</>}</div>}
        <div className='h-4 inline-block'>{children}</div>
      </div>
    </button>
  )
}

export default Button
